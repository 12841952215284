<template>
  <div v-if="isLoaded" class="regular-form-width">
    <h3>Timeout Settings</h3>
    <div class="user-authentication-settings__timeout-container">
      <div class="user-authentication-settings__input">
        <atom-number-input id="session-timeout-duration"
                           v-model="userAuthenticationSettings.timeoutSettings.duration"
                           label="Duration"
                           :error-message="validationErrors.timeoutSettings.duration"/>
      </div>
      <div class="user-authentication-settings__input">
        <atom-select id="session-timeout-time-unit"
                     v-model="selectedTimeUnit"
                     :options="timeUnitOptions"
                     label="Unit"
                     :error-message="validationErrors.timeoutSettings.timeUnit"/>
      </div>
    </div>

    <div v-if="getSystemLevelConfigOptionValue('MULTI_FACTOR_AUTHENTICATION') && getFeatureFlag('REMEMBER_MY_DEVICE')">
      <h3>Multi-Factor Authentication Settings</h3>
      <div class="user-authentication-settings__timeout-container">
        <div class="user-authentication-settings__input">
          <atom-number-input id="mfa-duration-days"
                             v-model="mfaDurationDays"
                             width="one-half"
                             label="Duration (days)"
                             hint-text="The duration before users are asked to provide MFA again after saving their browser."
                             :error-message="validationErrors.mfaSettings.durationDays"/>
        </div>
      </div>
    </div>

    <div class="floating-actions-bar fixedsticky">
      <ul class="menu-out flow-across">
        <li>
          <atom-button label="Save" type="primary" @click="saveSessionTimeoutSettings"/>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  getUserAuthenticationSettings,
  saveUserAuthenticationSettings,
  UserAuthenticationSettings,
  UserAuthenticationSettingsValidationErrors,
} from "authentication/js/api/user-authentication-settings.api";
import AtomNumberInput from "core/components/atom/numberInput/AtomNumberInput.vue";
import { getTimeUnitOptions, TimeUnit } from "authentication/js/enum/time-unit";
import AtomSelect from "core/components/atom/select/AtomSelect.vue";
import AtomButton from "core/components/atom/button/AtomButton.vue";
import { showChangesSavedFlash, showValidationFlash } from "core/js/flash-message";
import { useVNavigationGuard } from "core/components/composable/v-navigation-guard";
import { validateUserAuthenticationSettings } from "authentication/js/util/user-authentication-settings-util";
import { useConfigOptionStore, useFeatureFlagStore } from "control-panel/control-panel.globals";

export default defineComponent({
  components: {
    AtomButton,
    AtomSelect,
    AtomNumberInput,
  },
  setup() {
    const { initNavigationGuard, resetNavigationGuard } = useVNavigationGuard();
    const { getFeatureFlag } = useFeatureFlagStore();

    const configOptionStore = useConfigOptionStore();
    configOptionStore.fetchConfigOptions();
    const { getSystemLevelConfigOptionValue } = configOptionStore;

    return {
      initNavigationGuard,
      resetNavigationGuard,
      getFeatureFlag,
      getSystemLevelConfigOptionValue,
    };
  },
  data() {
    return {
      userAuthenticationSettings: {} as UserAuthenticationSettings,
      validationErrors: { timeoutSettings: {}, mfaSettings: {} } as UserAuthenticationSettingsValidationErrors,
      timeUnitOptions: getTimeUnitOptions(),
      isLoaded: false,
    };
  },
  computed: {
    selectedTimeUnit: {
      get() {
        return this.timeUnitOptions.find(timeUnit => timeUnit.id === this.userAuthenticationSettings.timeoutSettings.timeUnit);
      },
      set(newValue: {id: TimeUnit}) {
        this.userAuthenticationSettings.timeoutSettings.timeUnit = newValue.id;
      },
    },
    mfaDurationDays: {
      get() {
        return this.userAuthenticationSettings.mfaSettings?.durationDays ?? "";
      },
      set(value: string) {
        if (!this.userAuthenticationSettings.mfaSettings) {
          this.userAuthenticationSettings.mfaSettings = {};
        }
        this.userAuthenticationSettings.mfaSettings.durationDays = value;
      },
    },
  },
  async created() {
    this.userAuthenticationSettings = await getUserAuthenticationSettings();
    this.initNavigationGuard(this, "userAuthenticationSettings");
    this.isLoaded = true;
  },
  methods: {
    async saveSessionTimeoutSettings() {
      this.validationErrors = validateUserAuthenticationSettings(
        this.userAuthenticationSettings,
        this.getSystemLevelConfigOptionValue("MULTI_FACTOR_AUTHENTICATION"),
        this.getFeatureFlag("REMEMBER_MY_DEVICE"),
      );
      if (this.validationErrors.timeoutSettings.duration || this.validationErrors.mfaSettings.durationDays) {
        showValidationFlash();
      } else {
        try {
          await saveUserAuthenticationSettings(this.userAuthenticationSettings);
          showChangesSavedFlash();
          this.resetNavigationGuard();
        } catch (error: any) {
          if (error.status === 422) {
            this.validationErrors = error.responseJSON;
            showValidationFlash();
          } else {
            throw error;
          }
        }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.user-authentication-settings {
  &__timeout-container {
    display: flex;
    gap: 3rem;
  }
  &__input {
    flex-basis: 100%;
  }
}
</style>