import { ajaxGet, ajaxPut } from "core/js/spring-ajax";
import { TimeUnit } from "authentication/js/enum/time-unit";

const USER_AUTHENTICATION_SETTINGS_ENDPOINT = "/authentication/rest/api/settings/user-authentication";
const MFA_SETTINGS_ENDPOINT = "/authentication/rest/api/settings/user-authentication/mfa-settings";

export interface UserAuthenticationSettings {
  timeoutSettings: SessionTimeoutSettings,
  mfaSettings?: MfaSettings,
}

export interface MfaSettings {
  durationDays?: string,
}

export interface SessionTimeoutSettings {
  duration: string,
  timeUnit: TimeUnit,
}

export interface UserAuthenticationSettingsValidationErrors {
  timeoutSettings: SessionTimeoutSettingsValidationErrors
  mfaSettings: MfaSettingsValidationErrors;
}

export interface SessionTimeoutSettingsValidationErrors {
  duration?: string,
  timeUnit?: string,
}

export interface MfaSettingsValidationErrors {
  durationDays?: string,
}

export async function getUserAuthenticationSettings(): Promise<UserAuthenticationSettings> {
  const response = await ajaxGet<UserAuthenticationSettings>({ url: USER_AUTHENTICATION_SETTINGS_ENDPOINT });
  return {
    timeoutSettings: {
      duration: response.timeoutSettings.duration.toString(),
      timeUnit: response.timeoutSettings.timeUnit,
    },
    mfaSettings: {
      durationDays: response.mfaSettings?.durationDays,
    },
  };
}

export async function saveUserAuthenticationSettings(userAuthenticationSettings: UserAuthenticationSettings) {
  await ajaxPut({
    url: USER_AUTHENTICATION_SETTINGS_ENDPOINT,
    data: {
      timeoutSettings: {
        duration: +userAuthenticationSettings.timeoutSettings.duration,
        timeUnit: userAuthenticationSettings.timeoutSettings.timeUnit,
      },
      mfaSettings: {
        durationDays: userAuthenticationSettings.mfaSettings?.durationDays !== undefined
          ? Number(userAuthenticationSettings.mfaSettings.durationDays)
          : null,
      },
    },
  });
}

export async function getMfaSettings(): Promise<MfaSettings> {
  const response = await ajaxGet<MfaSettings>({ url: MFA_SETTINGS_ENDPOINT });
  return {
    durationDays: response.durationDays,
  };
}