<template>
  <login-layout :loading="loading" loading-text="Verifying your identity...">
    <template #body>
      <atom-info-box v-if="tokenParamMissing" priority="danger">
        This token link is invalid. Make sure it is the same as the one provided in the email you received.
      </atom-info-box>

      <template v-if="loginFailure">
        <atom-info-box v-if="invalidToken" priority="danger" role="alert">
          <p class="ecase-sign-in__body-text">
            The sign-in token used was invalid. This means that the token may have expired or was already used.
          </p>
          <p class="ecase-sign-in__body-text">
            Please try to <a :href="loginUrl">sign in</a> again.
          </p>
        </atom-info-box>

        <atom-info-box v-else-if="validationError.message" priority="danger" role="alert">
          {{ validationError.message }}
        </atom-info-box>

        <atom-info-box v-else priority="danger" role="alert">
          There was a problem with processing your sign-in token. Please contact your local support desk.
        </atom-info-box>
      </template>

      <template v-if="loginSuccess">
        <atom-info-box priority="success" role="alert">
          <p class="ecase-sign-in__body-text">
            Hi {{ userDisplayName }}, you have successfully signed into eCase.
          </p>
        </atom-info-box>

        <p>
          Would you like this device to be remembered?
        </p>

        <div class="yes-no-wrapper">
          <atom-button label="No" @click="redirectToLoginResultUrl"/>
          <atom-button label="Yes" @click="saveBrowserAndRedirect"/>
        </div>

        <p class="hint-text">
          If you select yes, email verification will not be required on this device for {{ mfaDisplayDuration }} days.
        </p>
        <p class="hint-text">
          You will still need to provide your username and password.
        </p>
        <p class="hint-text">
          This is not advised for shared computers.
        </p>
      </template>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import { loginWithToken } from "authentication/js/api/login.api.ts";
import springUrl from "core/js/spring-url.js";
import AtomButton from "core/components/atom/button/AtomButton.vue";
import { useUserStore } from "core/store/user.store";
import { useCurrentSessionStore } from "core/store/current-session.store";
import { useFeatureFlagStore } from "control-panel/control-panel.globals";
import { getMfaSettings } from "authentication/js/api/user-authentication-settings.api";
import { saveBrowser } from "authentication/js/api/saved-browsers.api";

const LOGIN_PATH = "/authentication/login";

export default {
  components: {
    AtomButton,
    LoginLayout,
    AtomInfoBox,
  },
  data() {
    return {
      loading: true,
      tokenParamMissing: false,
      loginStatus: undefined,
      validationError: {},
      loginResult: undefined,
      featureFlagStore: undefined,
      userStore: undefined,
      currentSessionStore: undefined,
      mfaSettings: undefined,
    };
  },
  computed: {
    loginSuccess() {
      return this.loginStatus === "SUCCESS";
    },
    loginFailure() {
      return this.loginStatus === "FAILURE";
    },
    invalidToken() {
      return this.validationError && this.validationError.reason === "INVALID_TOKEN";
    },
    loginUrl() {
      return springUrl(LOGIN_PATH);
    },
    userDisplayName() {
      if (this.loading || !this.userStore) {
        return "";
      } else {
        return this.userStore.getUserDisplayNameOrDefault(this.currentSessionStore.getUserId);
      }
    },
    mfaDisplayDuration() {
      return this.mfaSettings?.durationDays;
    },
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.submitToken(this.$route.query.token);
    } else {
      this.tokenParamMissing = true;
      this.loading = false;
    }
  },
  methods: {
    async submitToken(token) {
      try {
        this.loginResult = await loginWithToken(token);
        this.loginStatus = this.loginResult.loginStatus;

        if (this.loginSuccess) {
          this.featureFlagStore = useFeatureFlagStore();
          await this.featureFlagStore.isReady();
          if (!this.featureFlagStore.getFeatureFlag("REMEMBER_MY_DEVICE")) {
            this.redirectToLoginResultUrl();
            return;
          }
          this.userStore = useUserStore();
          this.currentSessionStore = useCurrentSessionStore();
          this.mfaSettings = await getMfaSettings();

          await Promise.all([this.userStore.isReady(), this.currentSessionStore.isReady()]);
        } else {
          this.validationError = {
            reason: this.loginResult.loginFailure.failureReason,
            message: this.loginResult.loginFailure.message,
          };
        }
        this.loading = false;
      } catch (err) {
        // setting loading to false in the catch as opposed to finally so that the loading animation remains visible
        // while the page is redirecting on successful token verification. Hide loading only in cases of unexpected errors.
        this.loading = false;
        throw err;
      }
    },
    async saveBrowserAndRedirect() {
      await saveBrowser();
      this.redirectToLoginResultUrl();
    },
    redirectToLoginResultUrl() {
      window.location.assign(this.loginResult.redirectUrl);
    },
  },
};
</script>
<style scoped lang="scss">
@use "core/scss/helpers/colour" as colour;
@use "core/scss/helpers/hint" as hint;

.yes-no-wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.hint-text {
  @include hint.ecase-hint;
}
</style>
